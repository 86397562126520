import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["storePaymentMethod", "amount", "currency"];

  toggleFields() {
    if (this.storePaymentMethodTarget.checked) {
      this.disable();
      var restore_value = this.amountTarget.dataset["oldvalue"];
    } else {
      this.restore();
    }
  }

  restore() {
    this.currencyTarget.disabled = false;
    this.amountTarget.disabled = false;
    this.amountTarget.value = this.amountTarget.dataset["oldvalue"];
  }

  disable() {
    this.currencyTarget.disabled = true;
    this.amountTarget.disabled = true;
    this.amountTarget.dataset["oldvalue"] = this.amountTarget.value;
    this.amountTarget.value = 0;
  }
}
