import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["amountSelect", "priceSelect"];

  connect() {
    this.updatePrice();
  }

  updatePrice() {
    let amount = this.amountSelectTarget.value;
    this.priceSelectTarget.value = amount;
    this.priceSelectTarget.max = amount;
  }
}
