import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["mode"];

  connect() {
    this.changeMode();
  }

  changeMode() {
    switch (this.modeTarget.value) {
      case "basic":
        $(".basic-option").show();
        $(".advanced-option").hide();
        break;
      default:
        $(".basic-option").hide();
        $(".advanced-option").show();
        break;
    }
  }
}
