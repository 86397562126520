import { Controller } from "stimulus";
import { basicSetup, EditorView } from "codemirror";
import { html } from "@codemirror/lang-html";
import { dracula } from "@uiw/codemirror-theme-dracula";

export default class extends Controller {
  static targets = ["buffer"];

  connect() {
    let textarea = this.bufferTarget;
    textarea.style.display = "none";

    let view = new EditorView({
      highlightWhitespace: true,
      doc: textarea.value,
      extensions: [basicSetup, html(), dracula],
    });

    textarea.insertAdjacentElement("afterend", view.dom);

    textarea.form.addEventListener("submit", (e) => {
      e.preventDefault();
      textarea.value = view.state.doc.toString();
      textarea.form.submit();
    });
  }
}
