import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "freeCancellation",
    "farCharge",
    "farChargeWrap",
    "nearToFarHours",
    "nearToFarTime",
    "isRefundable",
    "isRefundableWrap",
    "nearCharge",
    "noShowCharge",
    "refundableOptions"
  ]

  connect() {
    this.hideFarCharge()
    this.alterNearToFarTime()
  }

  hideFarCharge() {
    this.freeCancellationTargets.forEach((input) => {
      if (input.checked) {
        let style = input.value == 'true' ? 'none' : 'block'
        this.farChargeWrapTarget.style.display = style
        this.isRefundableWrapTarget.style.display = style
        if (style == 'block') {
          this.setAsNonRefundable();
          this.refundableOptionsTarget.classList.add("visibility-hidden");
        } else {
          this.refundableOptionsTarget.classList.remove("visibility-hidden");
        }
      }
    })
  }

  setAsNonRefundable() {
    this.setNonRefundableOptions();
    this.isRefundableTargets.forEach((input) => {
      if (input.value == '1') {
        input.checked = true
      }
    })
  }

  toggleRefundable() {
  this.refundableOptionsTarget.classList.toggle("visibility-hidden");
    this.isRefundableTargets.forEach((input) => {
      if (input.checked) {
        input.value == '1' ? this.setNonRefundableOptions() : this.setRefundableOptions()

      }
    })
  }
  alterNearToFarTime() {
    this.nearToFarTimeTarget.textContent = `fewer than ${this.convertNearToFarHours()}`
  }

  convertNearToFarHours() {
    let hours = this.nearToFarHoursTarget.value
    return hours <= 48 ? `${hours} hours` : `${hours / 24} days`
  }

  setNonRefundableOptions() {
    this.farChargeTarget.value = "100";
    this.nearToFarHoursTarget.value = "24";
    this.nearChargeTarget.value = "100";
    this.noShowChargeTarget.value = "100";
  }

  setRefundableOptions() {
    this.farChargeTarget.value = "10";
    this.nearToFarHoursTarget.value = "24";
    this.nearChargeTarget.value = "10";
    this.noShowChargeTarget.value = "10";
  }
}
