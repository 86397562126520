import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.currentImage = null
    this.addEvents()
  }

  static targets = ['backgroundImageInput']

  addEvents() {
    this.backgroundImageInputTarget.addEventListener('change', () =>
      this.renderPreview()
    )
  }

  renderPreview() {
    $.ajax({
      type: 'PUT',
      url: "/admin/promos/preview",
      data: this.extractFormData(),
      processData: false,
      contentType: false,
      success: function(data) {
        $(".promo_container .inner").replaceWith("<div class='inner'>" + data + "</div>")
      },
      error: function(error) {
        $(".promo_container .inner").replaceWith("<div class='inner'>Unable to show promo. Please check the details and try again.</div>")
      }
    })
    this.toggleVisibilityWarning()
  }

  extractFormData() {
    return new FormData(this.backgroundImageInputTarget.form);
  }

  toggleVisibilityWarning() {
    let form = this.backgroundImageInputTarget.form
    if(form.promo_active.checked && form.promo_featured.checked) {
      $('.not_featured').not(".hidden").addClass('hidden')
    } else {
      $('.not_featured.hidden').removeClass('hidden')
    }
  }
}
