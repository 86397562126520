import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'occupant',
    'feedback'
  ]

  checkMax() {
    let submit_button = document.getElementById('continuationFormSubmit')
    submit_button.disabled = this.maxOccupancyExceeded()
    this.maxOccupancyExceeded() ? this.displayFeedback() : this.hideFeedback()
  }

  maxOccupancyExceeded() {
    let max_occupancy = parseInt(this.data.get('max-allowed'))
    let total_occupancy = this.getTotalOccupancy()
    return total_occupancy > max_occupancy
  }

  getTotalOccupancy() {
    let occupants = this.occupantTargets
    let total_selected = 0
    for (var key in occupants) {
        total_selected += parseInt(occupants[key].value)
    }
    return total_selected
  }

  displayFeedback() {
    this.feedbackTarget.classList.remove('hidden')
  }

  hideFeedback() {
    this.feedbackTarget.classList.add('hidden')
  }
}
