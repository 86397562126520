import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['redemptionCodeInput', 'voucherLink']

  connect() {
    this.updateLink()
  }

  updateLink() {
    let redemption_code = this.redemptionCodeInputTarget.value
    let submit_link = this.voucherLinkTarget

    if (redemption_code.length == 16) {
      submit_link.classList.remove('disabled')
      submit_link.href = '/gift_vouchers/' + redemption_code
    } else {
      submit_link.classList.add('disabled')
      submit_link.removeAttribute('href')
    }
  }
}
