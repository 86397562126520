import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ 'source', 'button' ]

  connect() {
    if (document.queryCommandSupported('copy')) {
      this.buttonTarget.classList.add('clipboard--supported')
      this.el = this.sourceTarget
    }
  }

  copy(event) {
    event.preventDefault()

    this._patchElementAndSelect()

    this.el.focus()
    if (document.execCommand('copy')) {
      this._updateButtonText()
    }
  }

  // private

  _updateButtonText() {
    this.buttonTarget.textContent = 'Copied!'
    this.buttonTarget.classList.toggle('btn-success', 'btn-info')
  }

  _patchElementAndSelect() {
    let oldContentEditable = this.el.contentEditable,
        oldReadOnly = this.el.readOnly

    this.el.contentEditable = true
    this.el.readOnly = false

    this._selectRange()

    this.el.contentEditable = oldContentEditable
    this.el.readOnly = oldReadOnly
  }

  _selectRange() {
    let range = document.createRange()
    range.selectNodeContents(this.el)

    let s = window.getSelection()
    s.removeAllRanges()
    s.addRange(range)

    this.el.setSelectionRange(0, 999999)
  }
}
