import { Controller } from 'stimulus'

export default class extends Controller {
  containIframe() {
    const event = new Event('containIframe')
    document.body.dispatchEvent(event)
  }

  overflowIframe() {
    const event = new Event('overflowIframe')
    document.body.dispatchEvent(event)
  }
}
