import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.updatePreview()
  }

  static targets = [
    'linkLocationInput',
    'testLink',
    'testLinkContainer'
  ]

  updatePreview() {
    let test_link_container = this.testLinkContainerTarget
    let link_location = this.linkLocationInputTarget.value
    if (!link_location.length) {
      test_link_container.style.display = 'none'
    } else {
      test_link_container.style.display = 'block'
    }
    this.testLinkTarget.innerHTML = link_location
    this.testLinkTarget.href = link_location
  }
}
