import { DataBindingController } from 'stimulus-data-bindings'

export default class extends DataBindingController {
  static targets = ['container', 'input', 'button']

  connect() {
    this._loadDefaults()
  }

  checkAndUpdate(e) {
    if (this.inputTarget.value) {
      this.update(e)
    }
    else {
      this._setButtonDefault()
    }
  }

  // private

  _loadDefaults() {
    if (this.buttonTarget.text < 1) {
      this._setButtonDefault()
    }
  }

  _setButtonDefault() {
    this.buttonTarget.textContent = 'Custom CTA'
  }
}
