import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['kind', 'standardOptions', 'tailoredOptions']

  initialize() {
    this.togglePageOptions()
  }

  togglePageOptions() {
    this.setOptionStates()
    this.toggleOptionDisplay()
  }

  setOptionStates() {
    let standard = this.standardOptionsTarget
    let tailored = this.tailoredOptionsTarget
    this.activeOption = this.isStandard ? standard : tailored
    this.inactiveOption = this.isStandard ? tailored : standard
  }

  get isStandard() {
    return this.kindTarget.value == 'standard'
  }

  toggleOptionDisplay() {
    this.toggleVisibility()
    this.setDisabled(this.activeOption, false)
    this.setDisabled(this.inactiveOption, true)
  }

  toggleVisibility() {
    this.activeOption.style.display = 'block'
    this.inactiveOption.style.display = 'none'
  }

  setDisabled(element, disabled) {
    let inputs = this.optionInputs(element)
    inputs.forEach(input => input.disabled = disabled)
  }

  optionInputs(element) {
    return element.querySelectorAll("select, input")
  }
}
