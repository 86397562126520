import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['partOne', 'partTwo']

  transition(event) {
    event.preventDefault()
    this.transitionElements(this.partOneTarget, this.partTwoTarget)
  }

  transitionElements(out_elm, in_elm) {
    out_elm.classList.add('animate__backOutLeft')
    setTimeout(function(){
      out_elm.classList.add('hidden')
      in_elm.classList.remove('hidden')
      in_elm.classList.add('animate__backInRight')
    }, 550);
  }
}
