import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["childCount", "agesForm"];

  displayForm() {
    let childCount = parseInt(this.childCountTarget.value);
    let agesForm = this.agesFormTarget;
    let existingFields = agesForm.querySelectorAll(
      ".js-child-age:not([disabled])",
    );

    agesForm.classList.toggle("hidden", childCount == 0);

    // If there are more fields than needed, remove the excess fields
    if (existingFields.length > childCount) {
      for (let i = existingFields.length - 1; i >= childCount; i--) {
        existingFields[i].parentNode.removeChild(existingFields[i]);
      }
    }

    // If there are fewer fields than needed, add the necessary fields
    for (let i = existingFields.length; i < childCount; i++) {
      let ageField = document.querySelector(".js-child-age").cloneNode(true);
      ageField.disabled = false;
      agesForm.firstElementChild.appendChild(ageField);
    }
  }

  closeForm(e) {
    this.agesFormTarget.classList.add("hidden");
    e.preventDefault();
  }

  removeCurrentAges() {
    document
      .querySelectorAll(".js-child-age:not([disabled])")
      .forEach((el) => el.parentNode.removeChild(el));
  }
}
