import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.updatePreview()
  }

  static targets = [
    'titleInput',
    'descriptionInput',
    'textColourInput',
    'linkLocationInput',
    'backgroundColourInput',
    'title',
    'description',
    'container',
    'link_btn',
  ]

  updatePreview() {
    let title = this.titleInputTarget.value
    let description = this.descriptionInputTarget.value || this.descriptionInputTarget.placeholder
    let text_colour = this.textColourInputTarget.value
    let link_location = this.linkLocationInputTarget.value
    let background_colour = this.backgroundColourInputTarget.value
    this.titleTarget.innerHTML = title
    this.descriptionTarget.innerHTML = description
    this.titleTarget.style.color = text_colour
    this.descriptionTarget.style.color = text_colour
    this.containerTarget.style.backgroundColor = background_colour

    if (!link_location.length) {
      this.containerTarget.classList.add('no-cta')
    }
    else {
      this.containerTarget.classList.remove('no-cta')
    }

    if (!title.length && !link_location.length) {
      this.containerTarget.classList.add('center')
    }
    else {
      this.containerTarget.classList.remove('center')
    }
  }
}
