import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["activationDisabled"];

  handleClick() {
    if (this.canShowDisabledAnimation()) {
      const target = this.activationDisabledTarget;

      if (target.classList.contains("animate__animated")) {
        target.classList.remove("animate__animated", "animate__shakeX");
      }

      void target.offsetWidth;

      target.classList.add("animate__animated", "animate__shakeX");
    }
  }

  canShowDisabledAnimation() {
    return this.hasActivationDisabledTarget &&
      this.activationDisabledTarget.classList.contains("can-override-false")
  }
}
