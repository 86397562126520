import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['plan']

  subscribe() {
    let cbInstance = Chargebee.getInstance()
    let cart = cbInstance.getCart()

    let product = cbInstance.initializeProduct(this.planCode, this.data.get('quantity'))
    product.data["cf_owner_id"] = this.data.get('customer')
    product.data["cf_owner_type"] = 'BookableOwner'
    cart.replaceProduct(product)

    cart.proceedToCheckout()
  }

  get planCode() {
    return this.planTargets.find(input => input.checked).value
  }
}
