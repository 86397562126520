import { Controller } from "stimulus";

const COOKIE_NAME = "xcoverLaunchBanner";
const COOKIE_SILENCED = "silenced";
const COOKIE_DISMISSED = "dismissed";

const animateCSS = (element, animation, speed, prefix = 'animate__') => {
  return new Promise((resolve) => {
    const animationName = `${prefix}${animation}`;
    const classesToAdd = [`${prefix}animated`, animationName];

    if (speed) {
      classesToAdd.push(speed);
    }

    element.classList.add(...classesToAdd);

    function handleAnimationEnd(event) {
      event.stopPropagation();
      element.classList.remove(...classesToAdd);
      resolve('Animation ended');
    }

    element.addEventListener('animationend', handleAnimationEnd, { once: true });
  });
};

export default class extends Controller {
  static targets = ['xcoverActiveBanner', 'xcoverDismissedBanner']

  connect() {
    const cookieValue = this.getCookie(COOKIE_NAME);

    if (cookieValue === COOKIE_SILENCED) {
      this.hideAllBanners();
    }

    if (cookieValue === COOKIE_DISMISSED) {
      this.showDismissedBanner();
    }
  }

  dismissXcoverBanner(event) {
    event.preventDefault();
    this.showDismissedBanner();

    this.setCookie(COOKIE_NAME, COOKIE_DISMISSED, 30);
  }

  async silenceXcoverBanners() {
    await animateCSS(this.xcoverDismissedBannerTarget, "bounceOut", 'animate__faster');
    this.xcoverDismissedBannerTarget.classList.add("hidden");

    this.setCookie(COOKIE_NAME, COOKIE_SILENCED, 30);
  }

  async showDismissedBanner() {
    await animateCSS(this.xcoverActiveBannerTarget, "bounceOut", 'animate__faster');

    this.xcoverActiveBannerTarget.classList.add("hidden");
    this.xcoverDismissedBannerTarget.classList.remove("hidden");
  }

  async hideAllBanners() {
    this.xcoverActiveBannerTarget.classList.add("hidden");
    this.xcoverDismissedBannerTarget.classList.add("hidden");
  }

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/"
  }

  getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length == 2) {
      return parts.pop().split(";").shift();
    }
    return null;
  }
}
