import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['storedCards', 'saveCard']

  connect() {
    this.toggleSaveCard()
  }

  toggleSaveCard() {
    this._saveCardDisplay(this.storedCardsTarget.value)
  }

  // private

  _saveCardDisplay(usingStoredCard) {
    this.saveCardTarget.style.display = (usingStoredCard ? 'none' : 'block')
  }
}
