import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'childForm',
    'childInput',
    'feedback',
    'addChildButton'
  ]

  addChild(e) {
    e.preventDefault()
    this.childFormTarget.style.display = 'block'
    this.childInputTarget.focus()
  }

  closeForm(e) {
    e.preventDefault()
    this.childFormTarget.style.display = 'none'
  }

  checkAge() {
    let selected_age = parseInt(this.childInputTarget.value)
    let max_child_age = parseInt(this.childInputTarget.max)
    let min_child_age = parseInt(this.childInputTarget.dataset.minAge)
    let hard_min_child_age = parseInt(this.childInputTarget.min)

    if (selected_age == max_child_age || selected_age == min_child_age) {
      if (selected_age == max_child_age) {
        let message = 'Guests over ' + max_child_age + ' are considered Adults'
        this.displayFeedback(message)
      }
      if (selected_age == min_child_age && min_child_age == hard_min_child_age) {
        let message = 'This property does not allow Infants (ages under ' + min_child_age + ')'
        this.displayFeedback(message)
      }
    } else {
      this.hideFeedback()
    }

    if (selected_age < min_child_age) {
      this.addChildButtonTarget.value = 'Add Infant'
    } else {
      this.addChildButtonTarget.value = 'Add Child'
    }
  }

  displayFeedback(message) {
    this.feedbackTarget.classList.remove('hidden', 'fadeOut')
    this.feedbackTarget.classList.add('bounceIn')
    this.feedbackTarget.querySelector('span').textContent = message
  }

  hideFeedback() {
    this.feedbackTarget.classList.add('fadeOut')
  }
}
