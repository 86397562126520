import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['showFewer', 'showMore']

  connect() {
    this.renderNights(1)
  }

  showFewer(e) {
    e.preventDefault()
    this.renderNights(this.firstNight - 7)
  }

  showMore(e) {
    e.preventDefault()
    this.renderNights(this.firstNight + 7)
  }

  renderNights(start_night) {
    this.nightElements.forEach(el => this.toggleNight(el, start_night))
    this.refreshControls()
  }

  refreshControls() {
    this.toggleVisibility(this.showFewerTarget, this.firstNight != 1)
    this.toggleVisibility(this.showMoreTarget, this.firstNight != 29)
  }

  toggleNight(el, start_night) {
    let end_night = start_night + 6
    let night = parseInt(el.dataset.night)
    let shouldBeVisible = (night >= start_night && night <= end_night)

    this.toggleVisibility(el, shouldBeVisible)
  }

  get firstNight() {
    if(!this.firstVisibleNight) return 1
    return parseInt(this.firstVisibleNight.dataset.night)
  }

  get firstVisibleNight() {
    return Array.from(this.nightElements).find(el => this.isVisible(el))
  }

  get nightElements() {
    return document.querySelectorAll('.enquiry__option-list-item.night')
  }

  isVisible(element) {
    return element.offsetWidth > 0 && element.offsetHeight > 0
  }

  toggleVisibility(element, shouldBeVisible) {
    if(shouldBeVisible) {
      element.style.display = 'block'
      element.classList.add('animate__animated', 'animate__fadeIn')
    } else {
      element.style.display = 'none'
    }
  }
}
