import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'secretCodeUsed', 'secretCodeInput']

  revealForm(event) {
    event.preventDefault()
    this.formTarget.classList.remove('hidden')
    event.currentTarget.classList.add('hidden')
  }

  removeCode(event) {
    this.secretCodeUsedTarget.classList.add('hidden')
    this.formTarget.classList.remove('hidden')
    this.secretCodeInputTarget.value = ''
  }
}
