import { Controller } from "stimulus";
import { Fancybox } from "@fancyapps/ui";

export default class extends Controller {
  static targets = ["fancyImage"];

  connect() {
    this.loadSwiper();
    this.loadFancybox();
    this.hideFancyboxLinkIfIframed();
  }

  loadSwiper() {
    new Swiper(this.element, {
      speed: 400,
      observer: true,
      observeParents: true,
      preloadImages: false,
      lazy: {
        loadPrevNext: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }

  loadFancybox() {
    this.fancyImageTargets.forEach((element) => {
      const galleryId = element.dataset.fancybox;
      Fancybox.bind(`[data-fancybox='${galleryId}']`, {
        animated: false,
        compact: true,
        wheel: false,
        contentClick: "false"
      });
    });
  }

  hideFancyboxLinkIfIframed() {
    if (window.self !== window.top) {
      this.element.querySelectorAll('a[data-fancybox]').forEach(element => {
          element.classList.add('hidden-if-iframed');
      });
  }
  }
}
