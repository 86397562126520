import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    this.priceDescription = this.element.dataset.priceDescription
  }

  static targets = [
    'installButton',
    'price'
  ]

  install(e) {
    e.preventDefault()
    this.installButtonTarget.classList.add('compacted', 'disabled')
    this.installButtonTarget.innerHTML = "<i class='fa fa-cog fa-spin'></i>"
    let url = e.srcElement.attributes.href.textContent
    let description = this.priceDescription
    this.displayModal(url, description)
  }

  displayModal(url, description) {
    Notiflix.Confirm.show(
      'Install Extension?',
      description,
      'Install extension',
      'Go back',
      () => {
        $.ajax({
          url: url,
          type: 'post',
          beforeSend: function() {

          },
          success: function() {

          }
        })
      },
      () => {
        this.installButtonTarget.classList.remove('compacted', 'disabled')
        this.installButtonTarget.text = "install"
      },
      {
        backgroundColor: '#263D4D',
        borderRadius: '10px',
        titleColor: '#45ECA8',
        messageColor: 'white',
        okButtonColor: '#263D4D',
        okButtonBackground: '#45ECA8'
      }
    );
  }

}
