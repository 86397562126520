import { Controller } from "stimulus"

const DESKTOP_HEIGHT = 467;
const MOBILE_FROM_BOTTOM = 114;
const DESKTOP_FROM_BOTTOM = 192;
const POSITION = { top: '-55px', bottom: '30px' }
const MIN_IFRAME_WIDTH = 767;
const CHEVRON_UP = "<span class='fa fa-caret-up'></span>";
const CHEVRON_DOWN = "<span class='fa fa-caret-down'></span>";
const SMALL_SPINNER = '<i class="fa fa-spin fa-circle-o-notch"></i>';
const PADDING_FOR_DROPSHADOW = 30;

export default class extends Controller {

  connect() {
    $(this.element).data("nights_popup_controller", this);
  }

  launchPopup(event) {
    var linkClicked = $(event.currentTarget);
    var dayDiv = linkClicked.closest("div.js-day");
    var controller = this;
    this.closePopup();

    if ($('.js-nights-select', dayDiv).length > 0) {
      this.stashToolTipText(dayDiv);
      $(linkClicked).next().html(SMALL_SPINNER);
      $(linkClicked).parent().toggleClass('selected');
      this.positionNightsOverlayX(linkClicked, dayDiv);
      this.positionNightsOverlayY(linkClicked, dayDiv);
      $(linkClicked).on('ajax:success', function(e) {
        controller.positionNightsOverlayX(linkClicked, dayDiv);
        controller.positionNightsOverlayY(linkClicked, dayDiv);
      })
    } else {
      swal("Sorry!", "Check-in is not available on this day")
    }
  }

  closePopup() {
    $('.bliss .selected').removeClass('selected');
    $('.js-nights-select').html('');
    $('[data-toggle="tooltip"]').tooltip('hide');
    this.restoreToolTipText();
    this.resizeIframe(null)
  }

  positionNightsOverlayX(linkClicked, dayDiv) {
    var modal = $(".js-nights-select", dayDiv);
    var calendarWidth = linkClicked.closest('.calendar-days').width();
    var nightsLeftPosition = dayDiv.offset().left;
    var spaceToTheRight = calendarWidth - (nightsLeftPosition + modal.width())
    if (spaceToTheRight < 0) { dayDiv.addClass('right'); }
  }

  positionNightsOverlayY(linkClicked, dayDiv) {
    var closeWrap = $(".close-nights-wrap", dayDiv)[0];
    var modal = $(".js-nights-select", dayDiv)[0];
    dayDiv = dayDiv[0];
    var $iframeBody = linkClicked.closest(".iframe-css");
    if ($iframeBody.length > 0) {
      var iframeHeight = $iframeBody.height();
      var iframeWidth = $iframeBody.width();
      var fromBottom = iframeHeight - dayDiv.getBoundingClientRect().bottom;

      if (!modal) { return; }

      var modalHeight = modal.getBoundingClientRect().height;
      var maxModalHeight = dayDiv.getBoundingClientRect().top;

      if (iframeWidth > MIN_IFRAME_WIDTH && modalHeight < maxModalHeight) {
        if (iframeHeight <= DESKTOP_HEIGHT) {
          if (fromBottom <= MOBILE_FROM_BOTTOM) {
            this.reorientAsBottomAligned(modal, closeWrap);
          } else {
            this.reorientAsTopAligned(modal, closeWrap);
            this.reorientAsTopAligned(modal, closeWrap);
          }
        } else {
          if (fromBottom <= DESKTOP_FROM_BOTTOM) {
            this.reorientAsBottomAligned(modal, closeWrap);
          } else {
            this.reorientAsTopAligned(modal, closeWrap);
          }
        }
      } else {
        this.reorientAsTopAligned(modal, closeWrap);
      }
    } else {
      this.reorientAsTopAligned(modal, closeWrap);
    }
  }

  stashToolTipText(dayDiv) {
    if (!dayDiv.attr('data-original-title')) { return }
    this.storedDayDiv = dayDiv;
    this.storedToolTip = dayDiv.attr('data-original-title');
    dayDiv.removeAttr('data-original-title');
  }

  restoreToolTipText() {
    if (!this.storedToolTip) return;
    this.storedDayDiv.attr('data-original-title', this.storedToolTip);
    this.storedToolTip = null;
  }

  reorientAsBottomAligned(modal, closeWrap) {
    modal.style.bottom = POSITION.bottom;
    closeWrap.style.top = POSITION.top;
    closeWrap.getElementsByClassName("close-nights")[0].innerHTML = CHEVRON_DOWN;
    $('.bliss')[0].style.minHeight = ""
    this.resizeIframe(modal);
  }

  reorientAsTopAligned(modal, closeWrap) {
    modal.style.bottom = "";
    closeWrap.style.top = "";
    closeWrap.getElementsByClassName("close-nights")[0].innerHTML = CHEVRON_UP;
    this.resizeIframe(modal);
  }

  resizeIframe(modal) {
    if (modal) {
      $('.bliss')[0].style.minHeight = (modal.getBoundingClientRect().bottom + PADDING_FOR_DROPSHADOW) + "px";
    } else {
      $('.bliss')[0].style.minHeight = "";
    }
    $('body').trigger('containIframe')
  }
}
