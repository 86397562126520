import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dropdown", "dropdownIcon"];

  connect() {
    this.setupListeners();
  }

  setupListeners() {
    const options = this.dropdownTarget.querySelectorAll(
      ".gradient-option-link",
    );
    options.forEach((link) => {
      // Add click listener to apply the theme, send request, and update the theme name display
      link.addEventListener("click", this.updateNavbar.bind(this));
    });
  }

  updateNavbar(event) {
    event.preventDefault();
    const navbar = document.querySelector(".navbar");
    if (!navbar) return;

    // Remove all potential classes that may have been applied previously
    this.dropdownTarget
      .querySelectorAll(".gradient-option-link")
      .forEach((link) => {
        const className = link.dataset.value;
        navbar.classList.remove(className);
      });

    // Add the class associated with the selected option immediately upon click
    const selectedLink = event.currentTarget;
    const className = selectedLink.dataset.value;
    if (className) {
      navbar.classList.add(className);
      this.sendThemeUpdateRequest(className);
    }
  }

  sendThemeUpdateRequest(theme) {
    fetch("/admin/admin_customisations", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify({ admin_customisation: { navbar_theme: theme } }),
    }).then((response) => response.json());
  }
}
