import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['insuranceQuoteCheckbox', 'insuranceQuoteSubmit']

  toggleSubmit() {
    const insureCheckboxes = this.insuranceQuoteCheckboxTargets

    const checked = Array.from(insureCheckboxes).some(
      (checkbox) => checkbox.checked
    )

    this.insuranceQuoteSubmitTarget.disabled = !checked
  }
}
