import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['wrap']

  toggleDisplay(event) {
    event.preventDefault()
    this.removeOtherIntents()
    this.wrapTarget.classList.toggle('intent')
  }

  removeOtherIntents() {
    document.querySelectorAll('.nav-section--with-sub-menu').forEach(el => {
      if(el != this.wrapTarget) {
        el.classList.remove('intent')
      }
    })
  }
}
