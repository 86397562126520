import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["description", "radio", "bookButton"];

  updateSelectedRateType(event) {
    const clickedRadio = event.currentTarget;
    const descriptionId = clickedRadio.dataset.description;

    this.descriptionTargets.forEach((description) => {
      if (description.id === descriptionId) {
        description.classList.remove("hidden");
        description.classList.add(
          "animate__animated",
          "animate__fadeIn",
          "animate__faster",
        );
        description.addEventListener(
          "animationend",
          () => {
            description.classList.remove(
              "animate__animated",
              "animate__fadeIn",
              "animate__faster",
            );
          },
          { once: true },
        );
      } else {
        description.classList.add("hidden");
      }
    });

    this.bookButtonTarget.classList.add("shimmer");
    this.bookButtonTarget.addEventListener(
      "animationend",
      () => {
        this.bookButtonTarget.classList.remove("shimmer");
      },
      { once: true },
    );
  }
}
