import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "updateAmount",
    "amount",
    "amountSelect",
    "price",
    "validity",
  ];

  connect() {
    this.updateAmount();
    this.updatePrice();
    this.updateValidity();
  }

  updatePrice() {
    var voucher_product_id = this.amountSelectTarget.value;
    var prices = document.getElementsByClassName("current");
    while (prices.length) prices[0].classList.remove("current");
    var price = document.getElementById("price" + voucher_product_id);
    price.classList.add("current");
  }

  updateAmount() {
    var option =
      this.amountSelectTarget.options[this.amountSelectTarget.selectedIndex];
    var selected_amount = option.text;
    this.amountTarget.textContent = selected_amount;
  }

  updateValidity() {
    const voucherProductId = this.amountSelectTarget.value;
    const validityElements = document.querySelectorAll(".validity-length");
    validityElements.forEach((validity) => validity.classList.add("hidden"));
    const validityElement = document.getElementById(
      `validity${voucherProductId}`,
    );
    if (validityElement) {
      validityElement.classList.remove("hidden");
    }
  }
}
