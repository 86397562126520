import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "guestTitleInput",
    "guestFirstNameInput",
    "guestLastNameInput",
    "bookerTitleInput",
    "bookerFirstNameInput",
    "bookerLastNameInput",
    "termsCheckboxWrapper",
    "termsCheckbox",
    "submitButton",
  ];

  connect() {
    this.handleFormSubmit();
  }

  updateBookerTitle() {
    this.bookerTitleInputTarget.value = this.guestTitleInputTarget.value;
  }

  updateBookerFirstName() {
    this.bookerFirstNameInputTarget.value =
      this.guestFirstNameInputTarget.value;
  }

  updateBookerLastName() {
    this.bookerLastNameInputTarget.value = this.guestLastNameInputTarget.value;
  }

  handleFormSubmit(event) {
    if (!this.termsCheckboxTarget.checked) {
      event.preventDefault();

      this.termsCheckboxWrapperTarget.classList.remove(
        "animate__animated",
        "animate__headShake",
      );

      void this.termsCheckboxTarget.offsetWidth;
      void this.termsCheckboxWrapperTarget.offsetWidth;

      this.termsCheckboxWrapperTarget.classList.add(
        "animate__animated",
        "animate__headShake",
      );
    }
  }
}
